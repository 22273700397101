body {
  background-color: rgb(30, 30, 30);
  width: 100%;
  overflow-x: hidden;
  overflow-y: inherit;
}

#header {
  background-color: rgb(1, 86, 51);
  border-bottom: 1px solid rgba(225, 225, 225, 0.7);
  color: white;
  align-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  top: 0;
}

#table {
  margin-top: auto;
  width: 100%;
  margin-bottom: 50px;
}

#filters {
  background-color: rgb(25, 26, 26);
  height: 250px;
  width: 100%;
  border-top: 1px #555 solid;
  border-bottom: 1px #555 solid;
  display: block;
}

#filter-heading {
  margin-left: 10px;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}

#choices {
  display: block;
  margin-left: 10px;
  width: 100%;
  height: 100%;
}

#clgInfo {
  display: flex;
  padding: 10px;
}

#examinationInfo {
  margin-right: 70px;
}

#rankInfo {
  margin-right: 80px;
}

#programInfo {
  margin-left: 50px;
  max-width: 300px;
}

#clgNameInfo {
  margin-left: 50px;
  max-width: 300px;
}

/* 
#typeClgInfo{
  margin-left: 50px;
} */

#poolInfo {
  margin-left: 50px;
  max-width: 300px;
}

#durationInfo {
  margin-left: 50px;
}

#personalInfo {
  display: flex;
  padding: 10px;
}

#quotaInfo {
  margin-left: 70px;
  max-width: 300px;
}

.infoButton {
  background-color: rgba(0, 95, 56, 0.687);
  width: 100%;
  /* max-width: 300px; */
  height: 30px;
  font-size: 20px;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-transform: capitalize;
  color: white;
  border-radius: 10px;
  border: none;

  /* white-space: nowrap; */
  overflow: hidden;
}

.dropdownSelect {
  width: 100%;
  color: black;
  /* max-width: 300px; */
  height: 34px;
  text-align: center;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 10px;
}

.dropdown-content {
  max-width: 200px;
  background-color: black;
}

#rank {
  width: 85px;
  height: 30px;
  text-align: center;
  background-color: rgba(225, 225, 225, 0.7);
  border: none;
  border-radius: 10px;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

#table {
  width: 100%;
}

#tableContainer {
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  width: 100%;
}

/* #mainTable{
  font-size: 20px;
  color: white;
  background-color: #777;
  font-family: Arial, Helvetica, sans-serif;
  border: 1px white solid;
  width: 98%;
  margin: 1%;
} */

#keywords {
  font-size: 20px;
  margin-bottom: 15px;
  color: white;
  display: block;
  /* width: 98%;
  margin: 1%; */
}

#keywords thead {
  cursor: pointer;
  /* background:rgba(0, 95, 56, 0.687); */
  border: 1px white solid;
  border-radius: 10px;
  cursor: auto;
}

/* #keywords thead tr{
  display: flex;
  align-items: center;
  justify-content: center;
} */

#keywords thead tr th {
  cursor: pointer;
  border-left: 1px solid #555;
  border-right: 1px solid #777;
  border-top: 1px solid #555;
  border-bottom: 1px solid #333;
  box-shadow: inset 0 1px 0 #999;
  color: #fff;
  font-weight: bold;
  /* padding: 0px 30px; */
  padding: 5px;
  background-color: rgba(0, 95, 56, 0.687);
  /* position: relative; */
  text-shadow: 0 1px 0 #000;
  /* font-weight: bold;
  width: 100%;
  /* padding: 5px 10px; */
  /* padding-left: 42px; */
  /* border: 1px rgba(255, 255, 255, 0.406) solid;
  border-radius: 10px;
  background-color: rgba(0, 95, 56, 0.687);  */
}
/* #keywords thead tr th span { 
  padding-right: 20px;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  width: 100%;
} */

/* #keywords tbody tr{
  display: flex;
  align-items: center;
  justify-content: center;
} */

#keywords tbody tr td {
  border-right: 1px solid #555;
  border-left: 1px solid #555;
  border-top: 1px solid #555;
  border-bottom: 1px solid #555;
  /* padding: 0px 30px; */
  position: relative;
  transition: all 300ms;
  padding-left: 10px;
  text-align: left;
  padding: 10px;
  /* padding-right: 50px;
  padding-left: 30px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 20px; */

  /* /* text-align: center;
  width: 100%;
  /* padding: 15px 10px; */
  /* border: 1px rgba(255, 255, 255, 0.406) solid;
  border-radius: 10px;
  max-height: 30px;  */
}

#footer {
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  height: 40px;
  border-top: 1px solid rgba(225, 225, 225, 0.7);
  background-color: rgb(1, 86, 50);
  position: fixed;
  width: 100%;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#githubRepo {
  color: rgb(183, 183, 255);
}

#madeBy {
  display: inline-flex;
  justify-self: flex-end;
  margin-right: 20px;
}

#madeBy a {
  margin-left: 4px;
  color: rgb(183, 183, 255);
}

#pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

#pagination {
  display: block;
  margin-bottom: 50px;
}

#paginationButtons {
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.pageButton {
  color: white;
  width: 100px;
  height: 30px;
  background-color: rgb(1, 86, 50);
  border: 1px solid rgba(225, 225, 225, 0.7);
  font-size: 20px;
  margin: 5px;
  text-align: center;
  border-radius: 10px;
  white-space: nowrap;
}

#pagination h2 {
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}

#currPage {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.7);
  width: 120px;
  height: 30px;
  font-size: 20px;
  border: 1px solid rgb(1, 86, 50);
  border-radius: 10px;
}
